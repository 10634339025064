import * as React from "react"

import Button from '../components/button'

// markup
const UIComponent = () => {
  return (
    <main>
      <title>Summit Institute Home Page</title>
      <h1>Summit Institute Home Page</h1>
      <h2>Summit Institute Home Page</h2>
      <h3>Summit Institute Home Page</h3>
      <h4>Summit Institute Home Page</h4>
      <h5>Summit Institute Home Page</h5>
      <h6>Summit Institute Home Page</h6>
      <div>
        <Button colorSchema="primOutline">a</Button>
        <Button colorSchema="sec">a</Button>
      </div>
    </main>
  )
}

export default UIComponent